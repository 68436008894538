/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react'

// react-router-dom components
import { Link } from 'react-router-dom'

// @mui material components
import Card from '@mui/material/Card'
import Switch from '@mui/material/Switch'
import Grid from '@mui/material/Grid'
import MuiLink from '@mui/material/Link'

// @mui icons
import FacebookIcon from '@mui/icons-material/Facebook'
import GitHubIcon from '@mui/icons-material/GitHub'
import GoogleIcon from '@mui/icons-material/Google'
import InputAdornment from '@mui/material/InputAdornment'
import AccountCircle from '@mui/icons-material/AccountCircle'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import MDSnackbar from 'components/MDSnackbar'

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout'

// Images
import bgImage from 'assets/images/zerounorabbit/bg01.jpg'
import logo from 'assets/images/zerounorabbit/logo.png'

// ZeroUno
import { config } from 'variables/Config.js'
import authService from 'services/auth.service'
import { useForm } from 'react-hook-form'
import { CircularProgress } from '@mui/material'

function ResetPasswordPage () {
  const [rememberMe, setRememberMe] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()
  const [tr, setTR] = useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)

  const handleSetRememberMe = () => setRememberMe(!rememberMe)

  const reset = (formData) => {
    setIsLoadingSubmit(true)
    authService.forgotPassword(formData.email).then(
      (response) => {
        setIsLoadingSubmit(false)
        showNotification('success', response.data.message)
      },
      (error) => {
        setIsLoadingSubmit(false)
        if (error.response) {
          console.error(error.response.data.message)
          showNotification('error', error.response.data.message)
        } else {
          showNotification('error', 'errore reset')
        }
      }
    )
  }

  const showNotification = (type, message) => {
    setTR({
      sh: true,
      msg: message,
      color: type
    })
    setTimeout(function () {
      setTR({
        sh: false,
        msg: '',
        color: 'success'
      })
    }, 6000)
  }

  return (
    <BasicLayout image={bgImage}>
      <MDBox
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 1,
          m: 1
        }}
      >
        <MDBox
          component='img'
          src={logo}
          alt='logo'
          width='80%'
          height='80%'
          position='relative'
          mb={1}
          p={3}
        />
      </MDBox>

      <Card>
        <MDBox
          variant='gradient'
          bgColor='info'
          borderRadius='lg'
          coloredShadow='info'
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign='center'
        >
          <MDTypography variant='h4' fontWeight='light' color='white'>
            Password dimenticata
          </MDTypography>
          {config.SAMPLE_DATA && (
            <Grid
              container
              spacing={3}
              justifyContent='center'
              sx={{ mt: 1, mb: 2 }}
            >
              <Grid item xs={2}>
                <MDTypography
                  component={MuiLink}
                  href='#'
                  variant='body1'
                  color='white'
                >
                  <FacebookIcon color='inherit' />
                </MDTypography>
              </Grid>
              <Grid item xs={2}>
                <MDTypography
                  component={MuiLink}
                  href='#'
                  variant='body1'
                  color='white'
                >
                  <GitHubIcon color='inherit' />
                </MDTypography>
              </Grid>
              <Grid item xs={2}>
                <MDTypography
                  component={MuiLink}
                  href='#'
                  variant='body1'
                  color='white'
                >
                  <GoogleIcon color='inherit' />
                </MDTypography>
              </Grid>
            </Grid>
          )}
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <form onSubmit={handleSubmit(reset)}>
            <MDBox mb={2}>
              <MDInput
                type='email'
                label='email'
                fullWidth
                autoFocus
                error={!!errors.email}
                required
                autoComplete='email'
                {...register('email', { required: true })}
              />
            </MDBox>
            {config.SAMPLE_DATA && (
              <MDBox display='flex' alignItems='center' ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                  variant='button'
                  fontWeight='regular'
                  color='text'
                  onClick={handleSetRememberMe}
                  sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}
                >
                  &nbsp;&nbsp;Remember me
                </MDTypography>
              </MDBox>
            )}
            <MDBox mt={4} mb={1}>
              <MDButton
                variant='gradient'
                color='info'
                fullWidth
                type='submit'
                disabled={isLoadingSubmit}
              >
                reset
                {isLoadingSubmit && (
                  <CircularProgress
                    color='white'
                    style={{
                      width: '20px',
                      height: '20px',
                      marginLeft: '10px'
                    }}
                  />
                )}
              </MDButton>
            </MDBox>
          </form>
        </MDBox>
      </Card>
      <MDSnackbar
        color={tr.color}
        title=''
        icon='notifications'
        content={tr.msg}
        open={tr.sh}
        close={() => setTR(false)}
      />
    </BasicLayout>
  )
}

export default ResetPasswordPage
