/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from 'react'

// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Card from '@mui/material/Card'
// import Switch from '@mui/material/Switch'
import Grid from '@mui/material/Grid'
// import MuiLink from '@mui/material/Link'
import Link from '@mui/material/Link'
import Icon from '@mui/material/Icon'
import SyncIcon from '@mui/icons-material/Sync'
import CircularProgress from '@mui/material/CircularProgress'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Paper from '@mui/material/Paper'
import breakpoints from 'assets/theme/base/breakpoints'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDBadge from 'components/MDBadge'
import MDTypography from 'components/MDTypography'
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import MDSnackbar from 'components/MDSnackbar'
import DataTable from 'components/Tables/DataTable'
import Footer from 'layouts/authentication/components/Footer'
import MaterialReactTable from 'material-react-table'

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout'
import CoverLayout from 'layouts/authentication/components/CoverLayout'
import PageLayout from 'components/LayoutContainers/PageLayout'
import { darken } from '@mui/material'
// Images
import bgImage from 'assets/images/zerounorabbit/bg01.jpg'
import logo from 'assets/images/zerounorabbit/logo.png'

// ZeroUno
import { config } from 'variables/Config.js'
import prestashopService from 'services/prestashop.service'
import { useForm } from 'react-hook-form'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import utils from 'utils/utils.js'
import { DateTime } from 'luxon'
import { useInterval } from 'usehooks-ts'
import { useStore } from 'context/state.js' // zustand

export default function PrestaConnect() {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()
  const [errorMsg, setErrorMsg] = useState('')
  const [tr, setTR] = useState(false)
  const logged = useStore((state) => state.logged)
  const setLogged = useStore((state) => state.setLogged)
  const [details01, setDetails01] = useState([])
  const [details02, setDetails02] = useState([])
  const [details03, setDetails03] = useState([])
  const [details04, setDetails04] = useState([])
  const [details05, setDetails05] = useState([])
  const [marketPlace, setMarketPlace] = useState(new Map())
  const [customers, setCustomers] = useState([])
  const [customersAmz, setCustomersAmz] = useState(new Map())
  const [images, setImages] = useState(new Map())
  const [loading, setLoading] = useState(true)
  const [loadingAmz, setLoadingAmz] = useState(true)
  const [tabsOrientation, setTabsOrientation] = useState('horizontal')
  const [tab, setTab] = useState(0)

  const [showSnackbar, setShowSnackbar] = useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const [openConfirm, setOpenConfirm] = useState(false)
  const now = DateTime.now().startOf('day')

  useEffect(() => {
    setLoading(true)
    setLoadingAmz(true)
    fetchPrestashop()
    fetchAmazon()
  }, [])

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation('vertical')
        : setTabsOrientation('horizontal')
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener('resize', handleTabsOrientation)

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleTabsOrientation)
  }, [tabsOrientation])

  // useInterval(() => {
  //   if (logged) {
  //     setLoading(true)
  //     fetchPrestashop()
  //   }
  // }, 60000)

  // useInterval(() => {
  //   if (logged) {
  //     setLoadingAmz(true)
  //     fetchAmazon()
  //   }
  // }, 120000)

  const fetchPrestashop = () => {
    let orders = []
    let newCustomers = []
    let allCustomers = []
    let details = []
    let locations = []
    let productsId = []
    const parser = new DOMParser();

    prestashopService
      .getOrdersPrestashop()
      .then((response) => {
        orders = response.data.orders.filter((item) => {
          return !item.payment.startsWith('Amazon')
        })
        return orders
      })
      .then((orders) => {
        // console.log('orders: ', orders)
        // setOrders(orders)
        // take all customers
        const customersId = orders.map((item) => item.id_customer)
        // console.log('customersId: ', customersId)
        const myCustomersId = customers.map((item) => '' + item.id)
        // console.log('myCustomersId: ', myCustomersId)
        const customersToRetrieve = customersId.filter(
          (element) => !myCustomersId.includes(element)
        )
        // console.log('customersToRetrieve: ', customersToRetrieve)
        return prestashopService.getCustomers(customersToRetrieve)
      })
      .then((respCustomers) => {
        if (respCustomers.data.length == 0) {
          allCustomers = [...customers]
        } else {
          newCustomers = respCustomers.data.customers
          allCustomers = [...customers, ...newCustomers]
        }
        // console.log('allCustomers: ', allCustomers)
        // setCustomers(respCustomers.data.customers)
        // take all details
        const ordersId = orders.map((item) => item.id)
        return prestashopService.getDetails(ordersId)
      })
      .then(async (respDet) => {
        details = respDet.data.order_details
        var promiseImgId = details.map(async (ddd) => {
          var img = ''
          await prestashopService.getImageId(ddd.product_id)
            .then((responseXML) => {
              // console.log("🚀 ~ file: PrestaConnect.js:188 ~ .then ~ responseXML:", responseXML.data)
              const xmlDoc = parser.parseFromString(responseXML.data, "text/xml");
              const imageUrl = xmlDoc.querySelector("declination").getAttribute("xlink:href");
              const imageId = xmlDoc.querySelector("declination").getAttribute("id");
              img = imageId;
              
            }).catch((error) => {
              img = ''
            })
          return { ...ddd, imageId: img }
        })
        details = await Promise.all(promiseImgId)
        var promiseImg = details.map(async (ddd) => {
          var img = ''
          await prestashopService.getImage(ddd.product_id, ddd.imageId)
            .then((responseData) => {
              img = responseData.data;
            }).catch((error) => {
              img = ''
            })
          return { ...ddd, imageData: img }
        })
        details = await Promise.all(promiseImg)

        console.log('details: ', details)
        productsId = details.map((item) => item.product_id)
        // take all locations
        return prestashopService.getLocations(productsId)
      })
      .then(async (respLoc) => {
        locations = respLoc.data.stock_availables
        const ordDet = details.map((item) => {
          const order = utils.getObjectFromArray(orders, item.id_order)
          const ooo = {
            ...item,
            order: { ...order },
            customer: {
              ...utils.getObjectFromArray(allCustomers, order.id_customer)
            },
            marketplace: {},
            location: {
              ...utils.getObjectFromArrayAndLabel(
                locations,
                'id_product',
                item.product_id
              )
            }
          }
          return ooo
        })

        // setMarketPlace([...marketPlace, ...newMarketPlace])
        setCustomers(allCustomers)
        setDetails04(
          ordDet.filter((item) => item.order.payment.startsWith('eBay'))
        )
        setDetails05(
          ordDet.filter((item) => {
            return (
              !item.order.payment.startsWith('eBay') &&
              !item.order.payment.startsWith('Amazon') &&
              item.order.current_state === '51'
            )
          })
        )
        setLoading(false)
      })
      .catch((error) => {
        console.log('error: ', error)
        setLoading(false)
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      })
  }
  const fetchAmazon = () => {
    let orders = []
    let newCustomers = []
    const allCustomers = []
    let details = []
    let locations = []
    let productsId = []
    const mapOrders = new Map()
    let mapCustomers = new Map()
    const mapLocations = new Map()
    const mapImages = new Map()
    const parser = new DOMParser();

    prestashopService
      .getOrdersAmazon()
      .then((response) => {
        orders = response.data.orders
        orders.forEach((o) => {
          mapOrders.set(o.id, o)
        })
        return orders
      })
      .then((orders) => {
        // console.log('orders: ', orders)
        // take all customers
        const customersId = orders.map((item) => item.id_customer)
        const customersToRetrieve = customersId.filter(
          (element) => !customersAmz.has(parseInt(element.id))
        )
        return prestashopService.getCustomers(customersToRetrieve)
      })
      .then((respCustomers) => {
        mapCustomers = new Map(customersAmz.entries())
        if (respCustomers.data.customers) {
          newCustomers = respCustomers.data.customers
          newCustomers.forEach((o) => {
            mapCustomers.set(o.id, o)
          })
        }
        // console.log('mapCustomers: ', mapCustomers)
        // take all details
        return prestashopService.getDetails(Array.from(mapOrders.keys()))
      })
      .then(async (respDet) => {
        details = respDet.data.order_details
        var promiseImgId = details.map(async (ddd) => {
          var img = ''
          await prestashopService.getImageId(ddd.product_id)
            .then((responseXML) => {
              // console.log("🚀 ~ file: PrestaConnect.js:188 ~ .then ~ responseXML:", responseXML.data)
              const xmlDoc = parser.parseFromString(responseXML.data, "text/xml");
              const imageUrl = xmlDoc.querySelector("declination").getAttribute("xlink:href");
              const imageId = xmlDoc.querySelector("declination").getAttribute("id");
              img = imageId;
            }).catch((error) => {
              img = ''
            })
          return { ...ddd, imageId: img }
        })
        details = await Promise.all(promiseImgId)
        var promiseImg = details.map(async (ddd) => {
          var img = ''
          await prestashopService.getImage(ddd.product_id, ddd.imageId)
            .then((responseData) => {
              img = responseData.data;
            }).catch((error) => {
              img = ''
            })            
          return { ...ddd, imageData: img }
        })
        details = await Promise.all(promiseImg)

        // console.log('details: ', details)
        productsId = details.map((item) => item.product_id)
        // take all locations
        return prestashopService.getLocations(productsId)
      })
      .then(async (respLoc) => {
        locations = respLoc.data.stock_availables
        locations.forEach((o) => {
          mapLocations.set(o.id_product, o)
        })
        // console.log('mapLocations: ', mapLocations)
        const newMarketPlace = []
        let i = 0
        const ordDet = await Promise.all(
          details.map(async (item) => {
            const order = mapOrders.get(parseInt(item.id_order))
            // console.log('order: ', order)
            // let mkt = {}
            let mkt = marketPlace.get(item.id_order)
            if (!mkt) {
              const mktRes = await prestashopService.getMarketPlace(
                item.id_order
              )
              if (mktRes) {
                mkt = mktRes.data.marketplace_order
                newMarketPlace.push(mkt)
              }
            }
            // console.log('mkt: ', mkt)
            // console.log('mkt: ', i++)
            const ooo = {
              ...item,
              order: { ...order },
              customer: {
                ...mapCustomers.get(parseInt(order.id_customer))
              },
              marketplace: { ...mkt },
              location: {
                ...mapLocations.get(item.product_id)
              }
            }
            // console.log('ooo: ', ooo)
            return ooo
          })
        )
        const mktMap = new Map(marketPlace.entries())
        newMarketPlace.forEach((o) => {
          if (o) {
            mktMap.set(o.id_order, o)
          }
        })
        console.log('mktMap: ', mktMap)
        setMarketPlace(mktMap)
        setCustomersAmz(mapCustomers)
        setDetails01(
          ordDet.filter((item) => {
            if (item.marketplace && item.marketplace.latest_ship_date) {
              return (
                item.order.current_state === '51' &&
                DateTime.fromISO(
                  item.marketplace.latest_ship_date.substring(0, 10)
                )
                  .toISO()
                  .substring(0, 10) == now.toISO().substring(0, 10)
              )
            } else {
              return false
            }
          })
        )
        setDetails02(
          ordDet.filter((item) => {
            if (item.marketplace && item.marketplace.latest_ship_date) {
              return (
                item.order.current_state !== '51' &&
                DateTime.fromISO(
                  item.marketplace.latest_ship_date.substring(0, 10)
                )
                  .toISO()
                  .substring(0, 10) == now.toISO().substring(0, 10)
              )
            } else {
              return false
            }
          })
        )
        setDetails03(
          ordDet.filter((item) => {
            if (item.marketplace && item.marketplace.latest_ship_date) {
              return (
                item.order.current_state === '51' &&
                DateTime.fromISO(
                  item.marketplace.latest_ship_date.substring(0, 10)
                ) > now
              )
            } else {
              return false
            }
          })
        )
        setLoadingAmz(false)
      })
      .catch((error) => {
        console.log('error: ', error)
        setLoadingAmz(false)
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      })
  }

  const showNotification = (message) => {
    if (!tr) {
      setTR(true)
      setTimeout(function () {
        setTR(false)
      }, 6000)
      setErrorMsg(message)
    }
  }

  const login = (formData) => {
    setLogged(formData.password === 'pr3st4@01')
  }

  const columns = [
    {
      id: 'marketplace',
      header: 'marketplace',
      accessorFn: (d) => d.order.payment
    },
    {
      accessorKey: 'id_order',
      header: 'id_order'
    },
    {
      id: 'riferimento',
      header: 'riferimento',
      accessorFn: (d) => d.order.reference
    },
    {
      id: 'immagine',
      header: 'immagine',
      accessorFn: d => (d.imageId !== ''? <img src={d.imageData} width="100" /> : '')
    },
    {
      accessorKey: 'product_reference',
      header: 'rif. prodotto'
    },
    {
      accessorKey: 'product_ean13',
      header: 'ean. prodotto'
    },
    {
      accessorKey: 'product_name',
      header: 'nome'
    },
    {
      accessorKey: 'product_quantity',
      header: 'qta'
    },
    {
      id: 'locazione',
      header: 'locazione',
      accessorFn: (d) => d.location.location
    },
    {
      id: 'disponibili',
      header: 'disponibili',
      accessorFn: (d) => d.location.quantity
    },
    {
      id: 'nome',
      header: 'nome',
      accessorFn: (d) => d.customer.firstname
    },
    {
      id: 'cognome',
      header: 'cognome',
      accessorFn: (d) => d.customer.lastname
    }
  ]

  const handleChangeTab = (event, newValue) => {
    setTab(newValue)
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <MDBox sx={{ mt: 1, mb: 2 }}>{children}</MDBox>}
      </div>
    )
  }

  return (
    <PageLayout>
      <MDBox
        position='absolute'
        width='100%'
        minHeight='100vh'
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients }
          }) =>
            bgImage &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.3),
              rgba(gradients.dark.state, 0.3)
            )}, url(${bgImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      />
      <Card
        sx={{
          backgroundColor: '#FFFFFF00',
          ml: 5,
          mr: 5
        }}
      >
        <MDBox px={1} width='100%' height='100vh' mx='auto'>
          <Grid
            container
            spacing={1}
            justifyContent='center'
            alignItems='top'
            height='100%'
          >
            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
              <MDBox
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  m: 1
                }}
              >
                <MDBox
                  component='img'
                  src={logo}
                  alt='logo'
                  width='20%'
                  height='20%'
                  position='relative'
                  // mb={1}
                  p={1}
                />
              </MDBox>
              {logged && (
                <>
                  <MDBox pt={1} pb={1}>
                    <Card>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={3}>
                          <MDBox
                            m={1.5}
                            color='white'
                            bgColor='info'
                            variant='gradient'
                            borderRadius='lg'
                            shadow='lg'
                            opacity={1}
                            p={2}
                          >
                            Amazon oggi: {details01.length}
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <MDBox
                            m={1.5}
                            color='white'
                            bgColor='error'
                            variant='gradient'
                            borderRadius='lg'
                            shadow='lg'
                            opacity={1}
                            p={2}
                          >
                            Amazon non arrivate: {details02.length}
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <MDBox
                            color='white'
                            bgColor='success'
                            variant='gradient'
                            borderRadius='lg'
                            shadow='lg'
                            opacity={1}
                            p={2}
                            m={1.5}
                          >
                            Amazon domani: {details03.length}
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                          <MDButton
                            color='success'
                            sx={{ p: 2, m: 1.5 }}
                            onClick={() => {
                              setLoadingAmz(true)
                              fetchAmazon()
                            }}
                            startIcon={<SyncIcon />}
                          >
                            refresh amazon
                          </MDButton>
                        </Grid>
                        <Grid item xs={12} md={6} lg={1}>
                          {loadingAmz && <CircularProgress sx={{ m: 2 }} />}
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <MDBox
                            color='white'
                            bgColor='info'
                            variant='gradient'
                            borderRadius='lg'
                            shadow='lg'
                            opacity={1}
                            p={2}
                            m={1.5}
                          >
                            eBay: {details04.length}
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <MDBox
                            color='white'
                            bgColor='success'
                            variant='gradient'
                            borderRadius='lg'
                            shadow='lg'
                            opacity={1}
                            p={2}
                            m={1.5}
                          >
                            Prestashop: {details05.length}
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <MDBox
                            color='white'
                            bgColor='warning'
                            variant='gradient'
                            borderRadius='lg'
                            shadow='lg'
                            opacity={1}
                            p={2}
                            m={1.5}
                          >
                            totale: {details01.length + details02.length + details03.length + details04.length + details05.length}
                          </MDBox>
                        </Grid>

                        <Grid item xs={12} md={6} lg={2}>
                          <MDButton
                            color='success'
                            sx={{ p: 2, m: 1.5 }}
                            onClick={() => {
                              setLoading(true)
                              fetchPrestashop()
                            }}
                            startIcon={<SyncIcon />}
                          >
                            refresh prestashop
                          </MDButton>
                        </Grid>
                        <Grid item xs={12} md={6} lg={1}>
                          {loading && <CircularProgress sx={{ m: 2 }} />}
                        </Grid>
                      </Grid>
                    </Card>
                  </MDBox>

                  <MDBox pt={1} pb={1}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Tabs
                          orientation={tabsOrientation}
                          value={tab}
                          onChange={handleChangeTab}
                          indicatorColor='secondary'
                          textColor='inherit'
                        >
                          <Tab
                            label={'tutti (' + (details01.length + details02.length + details03.length + details04.length + details05.length) + ')'}
                          />
                          <Tab
                            label={'amazon oggi (' + details01.length + ')'}
                          />
                          <Tab
                            label={
                              'amazon in arrivo (' + details02.length + ')'
                            }
                          />
                          <Tab
                            label={'amazon domani (' + details03.length + ')'}
                          />
                          <Tab label={'eBay (' + details04.length + ')'} />
                          <Tab
                            label={'prestashop (' + details05.length + ')'}
                          />
                        </Tabs>
                      </Grid>
                    </Grid>
                    <TabPanel value={tab} index={0}>
                      <Paper elevation={3}>
                        <MaterialReactTable
                          columns={columns}
                          data={[...details01, ...details02, ...details03, ...details04, ...details05]}
                          initialState={{ density: 'compact' }}
                          muiTablePaperProps={{
                            elevation: 0,
                            sx: {
                              borderRadius: '0',
                              border: '0px dashed #e0e0e0'
                            }
                          }}
                          muiTableBodyProps={{
                            sx: (theme) => ({
                              '& tr:nth-of-type(odd)': {
                                backgroundColor: darken(
                                  theme.palette.background.default,
                                  0.1
                                )
                              }
                            })
                          }}
                        />
                      </Paper>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                      <Paper elevation={3}>
                        <MaterialReactTable
                          columns={columns}
                          data={details01}
                          initialState={{ density: 'compact' }}
                          muiTablePaperProps={{
                            elevation: 0,
                            sx: {
                              borderRadius: '0',
                              border: '0px dashed #e0e0e0'
                            }
                          }}
                          muiTableBodyProps={{
                            sx: (theme) => ({
                              '& tr:nth-of-type(odd)': {
                                backgroundColor: darken(
                                  theme.palette.background.default,
                                  0.1
                                )
                              }
                            })
                          }}
                        />
                      </Paper>
                    </TabPanel>
                    <TabPanel value={tab} index={2}>
                      <Paper elevation={3}>
                        <MaterialReactTable
                          columns={columns}
                          data={details02}
                          initialState={{ density: 'compact' }}
                          muiTablePaperProps={{
                            elevation: 0,
                            sx: {
                              borderRadius: '0',
                              border: '0px dashed #e0e0e0'
                            }
                          }}
                          muiTableBodyProps={{
                            sx: (theme) => ({
                              '& tr:nth-of-type(odd)': {
                                backgroundColor: darken(
                                  theme.palette.background.default,
                                  0.1
                                )
                              }
                            })
                          }}
                        />
                      </Paper>
                    </TabPanel>
                    <TabPanel value={tab} index={3}>
                      <Paper elevation={3}>
                        <MaterialReactTable
                          columns={columns}
                          data={details03}
                          initialState={{ density: 'compact' }}
                          muiTablePaperProps={{
                            elevation: 0,
                            sx: {
                              borderRadius: '0',
                              border: '0px dashed #e0e0e0'
                            }
                          }}
                          muiTableBodyProps={{
                            sx: (theme) => ({
                              '& tr:nth-of-type(odd)': {
                                backgroundColor: darken(
                                  theme.palette.background.default,
                                  0.1
                                )
                              }
                            })
                          }}
                        />
                      </Paper>
                    </TabPanel>
                    <TabPanel value={tab} index={4}>
                      <Paper elevation={3}>
                        <MaterialReactTable
                          columns={columns}
                          data={details04}
                          initialState={{ density: 'compact' }}
                          muiTablePaperProps={{
                            elevation: 0,
                            sx: {
                              borderRadius: '0',
                              border: '0px dashed #e0e0e0'
                            }
                          }}
                          muiTableBodyProps={{
                            sx: (theme) => ({
                              '& tr:nth-of-type(odd)': {
                                backgroundColor: darken(
                                  theme.palette.background.default,
                                  0.1
                                )
                              }
                            })
                          }}
                        />
                      </Paper>
                    </TabPanel>
                    <TabPanel value={tab} index={5}>
                      <Paper elevation={3}>
                        <MaterialReactTable
                          columns={columns}
                          data={details05}
                          initialState={{ density: 'compact' }}
                          muiTablePaperProps={{
                            elevation: 0,
                            sx: {
                              borderRadius: '0',
                              border: '0px dashed #e0e0e0'
                            }
                          }}
                          muiTableBodyProps={{
                            sx: (theme) => ({
                              '& tr:nth-of-type(odd)': {
                                backgroundColor: darken(
                                  theme.palette.background.default,
                                  0.1
                                )
                              }
                            })
                          }}
                        />
                      </Paper>
                    </TabPanel>
                    
                  </MDBox>

                  {false && (
                    <MDBox pt={1} pb={1}>
                      <Card sx={{ mt: 6 }}>
                        <ZUTitleForm
                          bgColor='info'
                          title='ordini amazon da preparare e evadere entro oggi'
                          icon='local_shipping'
                        />

                        <MDBox>
                          <MaterialReactTable
                            columns={columns}
                            data={details01}
                            initialState={{ density: 'compact' }}
                            muiTablePaperProps={{
                              elevation: 0,
                              sx: {
                                borderRadius: '0',
                                border: '0px dashed #e0e0e0'
                              }
                            }}
                            muiTableBodyProps={{
                              sx: (theme) => ({
                                '& tr:nth-of-type(odd)': {
                                  backgroundColor: darken(
                                    theme.palette.background.default,
                                    0.1
                                  )
                                }
                              })
                            }}
                          />
                        </MDBox>
                      </Card>

                      <Card sx={{ mt: 6 }}>
                        <ZUTitleForm
                          bgColor='info'
                          title='ordini amazon non arrivati da evadere oggi'
                          icon='local_shipping'
                        />

                        <MDBox>
                          <MaterialReactTable
                            columns={columns}
                            data={details02}
                            initialState={{ density: 'compact' }}
                            muiTablePaperProps={{
                              elevation: 0,
                              sx: {
                                borderRadius: '0',
                                border: '0px dashed #e0e0e0'
                              }
                            }}
                            muiTableBodyProps={{
                              sx: (theme) => ({
                                '& tr:nth-of-type(odd)': {
                                  backgroundColor: darken(
                                    theme.palette.background.default,
                                    0.1
                                  )
                                }
                              })
                            }}
                          />
                        </MDBox>
                      </Card>

                      <Card sx={{ mt: 6 }}>
                        <ZUTitleForm
                          bgColor='info'
                          title='ordini amazon da preparare e evadere da domani'
                          icon='local_shipping'
                        />

                        <MDBox>
                          <MaterialReactTable
                            columns={columns}
                            data={details03}
                            initialState={{ density: 'compact' }}
                            muiTablePaperProps={{
                              elevation: 0,
                              sx: {
                                borderRadius: '0',
                                border: '0px dashed #e0e0e0'
                              }
                            }}
                            muiTableBodyProps={{
                              sx: (theme) => ({
                                '& tr:nth-of-type(odd)': {
                                  backgroundColor: darken(
                                    theme.palette.background.default,
                                    0.1
                                  )
                                }
                              })
                            }}
                          />
                        </MDBox>
                      </Card>
                    </MDBox>
                  )}
                </>
              )}

              {!logged && (
                <Card>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={4} />
                    <Grid item xs={12} md={6} lg={4}>
                      <form onSubmit={handleSubmit(login)}>
                        <MDBox mt={4} mb={1}>
                          <MDInput
                            type='password'
                            label='Password'
                            fullWidth
                            error={!!errors.password}
                            required
                            {...register('password', { required: true })}
                          />
                        </MDBox>
                        <MDBox mt={4} mb={4}>
                          <MDButton
                            variant='gradient'
                            color='info'
                            fullWidth
                            type='submit'
                          >
                            accedi
                          </MDButton>
                        </MDBox>
                      </form>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} />
                  </Grid>
                </Card>
              )}

              <MDSnackbar
                color='error'
                icon='notifications'
                title='Errore'
                content={errorMsg}
                open={tr}
                close={() => setTR(false)}
              />
            </Grid>
          </Grid>
        </MDBox>
      </Card>
      {/* <Footer light /> */}
    </PageLayout>
  )
}
