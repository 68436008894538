'use strict'

import http from './http-common'
import authHeader from './auth-header'

const getOrdersReady = () => {
  return http.axiosClient.get(
    '/orders/?output_format=JSON&filter[current_state]=[51]&filter[payment]=[Amazon MarketPlace]&display=[id,id_customer,current_state,date_add,payment,reference]'
  )
}
const getOrdersNotArrived = () => {
  return http.axiosClient.get(
    '/orders/?output_format=JSON&filter[current_state]=![51,3,4,5,6,7,18,28,33,34]&filter[payment]=[Amazon MarketPlace]&display=full'
  )
}
const getOrdersAmazon = () => {
  return http.axiosClient.get(
    '/orders/?output_format=JSON&filter[current_state]=[51|2|36|45|49|50|54|86|97|101|103|105]&filter[payment]=[Amazon MarketPlace]&display=[id,id_customer,current_state,date_add,payment,reference]'
  )
  // '/orders/?output_format=JSON&filter[current_state]=[51|2|36|37|45|49|50|54|86|97|101|103|105]&filter[payment]=[Amazon MarketPlace]&display=[id,id_customer,current_state,date_add,payment,reference]'
}
const getOrdersEbay = () => {
  return http.axiosClient.get(
    '/orders/?output_format=JSON&filter[current_state]=[51|2|36|45|49|50|54|86|97|101|103|105]&filter[payment]=[eBay]%&display=[id,id_customer,current_state,date_add,payment,reference]'
  )
}
const getOrdersPrestashop = () => {
  return http.axiosClient.get(
    '/orders/?output_format=JSON&filter[current_state]=[51]&display=[id,id_customer,current_state,date_add,payment,reference]'
  )
}

const getCustomer = (id) => {
  return http.axiosClient.get(`/customers/${id}?output_format=JSON`)
}
const getCustomers = (list) => {
  const pipes = list.join('|')
  return http.axiosClient.get(
    `/customers/?output_format=JSON&filter[id]=[${pipes}]&display=[id,firstname,lastname]`
  )
}
const getDetails = (list) => {
  const pipes = list.join('|')
  return http.axiosClient.get(
    `/order_details/?output_format=JSON&filter[id_order]=[${pipes}]&sort=[id_order_ASC]&display=[id,id_order,product_id, product_reference,product_ean13,product_name,product_quantity]`
  )
}
const getLocations = (list) => {
  const pipes = list.join('|')
  return http.axiosClient.get(
    `/stock_availables/?output_format=JSON&display=[id,location,quantity,id_product]&filter[id_product]=[${pipes}]`
  )
}
// const getMarketPlaces = (list) => {
//   const pipes = list.join('|')
//   return http.axiosClient.get(`/marketplace_orders/?output_format=JSON&display=full&filter[id_order]=[${pipes}]`)
// }
const getMarketPlace = async (idOrder) => {
  return http.axiosClient.get(
    `/marketplace_orders/${idOrder}?output_format=JSON`
  )
}

const getImageId = (id) => {
  return http.axiosClient.get(
    `/images/products/${id}`
  )
}

const getImage = (idProd, idImg) => {
  return http.axiosClient.get(
    `/images/products/${idProd}/${idImg}`
  )
}

export default {
  getOrdersReady,
  getOrdersNotArrived,
  getOrdersAmazon,
  getCustomer,
  getCustomers,
  getDetails,
  getLocations,
  getMarketPlace,
  getOrdersEbay,
  getOrdersPrestashop,
  getImageId,
  getImage
  // getMarketPlaces
}
