import axios from 'axios'
import { config } from 'variables/Config.js'

class AuthService {
  login (email, password) {
    return axios
      .post(config.SERVER_URL + '/auth/login', { email, password })
      .then((response) => {
        if (response.data.accessToken) {
          sessionStorage.setItem('user', JSON.stringify(response.data))
        }
        return response.data
      })
  }

  logout () {
    sessionStorage.removeItem('user')
  }

  register (email, password) {
    return axios.post(config.SERVER_URL + 'signup', {
      email,
      password
    })
  }

  user () {
    return JSON.parse(sessionStorage.getItem('user'))
  }

  isAdmin () {
    const user = JSON.parse(sessionStorage.getItem('user'))
    return user && user.role && user.role === 'admin'
  }

  isUser () {
    const user = JSON.parse(sessionStorage.getItem('user'))
    return user && user.role && user.role === 'user'
  }

  isOperator () {
    const user = JSON.parse(sessionStorage.getItem('user'))
    return user && user.role && user.role === 'operator'
  }

  hasRole (role) {
    const user = JSON.parse(sessionStorage.getItem('user'))
    return user && user.role && user.role === role
  }

  forgotPassword (email) {
    return axios.post(config.SERVER_URL + '/auth/forgot-password', {
      email
    })
  }

  checkToken (token) {
    return axios.get(config.SERVER_URL + '/auth/check-token?token=' + token)
  }

  resetPassword (data) {
    return axios.post(config.SERVER_URL + '/auth/reset-password', data)
  }
}

export default new AuthService()
