/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React components
import MDAvatar from 'components/MDAvatar'

// @mui icons
import Icon from '@mui/material/Icon'

// Images
import profilePicture from 'assets/images/avatar.png'
// ZeroUno
import { config } from 'variables/Config.js'
import authService from 'services/auth.service'
import LoginPage from 'views/auth/login.js'
import MainDashboard from 'views/dashboard'
import UserProfile01 from 'views/users/UserProfile.js'
import UsersTable from 'views/users/UsersTable.js'
import UserForm from 'views/users/UserForm.js'
import ResetPasswordPage from 'views/auth/reset-password'
import ChangePasswordPage from 'views/auth/change-password'
import PrestaConnect from 'views/pages/PrestaConnect'

const loggedUser = authService.user()

const routes = [
  {
    type: 'collapse',
    name: loggedUser ? loggedUser.name + ' ' + loggedUser.surname : '',
    key: 'loggedUser',
    icon: <MDAvatar src={loggedUser && loggedUser.image ? config.SERVER_URL + '/download/' + loggedUser.image : profilePicture} alt='imgProfile' size='sm' />,
    collapse: [
      {
        name: 'Il Mio Profilo',
        key: 'il-mio-profilo',
        component: <UserProfile01 />,
        route: '/myprofile',
        invisible: false
      },
      {
        name: 'PrestaConnect',
        key: 'prestaconnect',
        route: '/prestaconnect',
        component: <PrestaConnect />,
        invisible: false
      },
      {
        name: 'Logout',
        key: 'logout',
        route: '/login',
        component: <LoginPage />,
        invisible: false
      },
      {
        name: 'Cambia password',
        key: 'change-password',
        route: '/change-password/:token',
        component: <ChangePasswordPage />,
        invisible: false
      }
    ]
  },
  { type: 'divider', key: 'divider-0' },
  {
    type: 'collapse',
    name: 'Dashboards',
    key: 'dashboards',
    icon: <Icon fontSize='medium'>dashboard</Icon>,
    collapse: [
      {
        type: 'collapse',
        name: 'Dashboard',
        key: 'dashboard',
        icon: <Icon fontSize='medium'>dashboard</Icon>,
        route: '/dashboards/main',
        component: <MainDashboard />
      }
    ]
  },
  {
    type: 'collapse',
    name: 'CRM',
    key: 'crm',
    icon: <Icon fontSize='medium'>apps</Icon>,
    collapse: [
      {
        name: 'Utente',
        key: 'user',
        route: '/users/:idUser',
        component: <UserForm />,
        invisible: true
      },
      {
        name: 'Utenti',
        key: 'users',
        route: '/users',
        component: <UsersTable />,
        invisible: !authService.isAdmin()
      }
    ]
  },
  {
    type: 'collapse',
    name: '01Rabbit',
    key: '01Rabbit',
    href: 'https://www.01rabbit.it',
    icon: <Icon fontSize='medium'>engineering</Icon>,
    noCollapse: true
  }
]

export default routes
