const prod = {
  // SERVER_URL: 'https://ANBZ4Y7K4E17JFR8HBZEIQZLIBVPG2EP@www.scontolo.net/api',
  SERVER_URL: 'https://scontolo.01rabbit.it/prestashop',
  SAMPLE_DATA: false,
  APP_NAME: 'PrestaConnect'
}

const dev = {
  SERVER_URL: 'http://localhost:8080/prestashop/',
  SAMPLE_DATA: false,
  APP_NAME: 'PrestaConnect dev.'
}

export const config = process.env.NODE_ENV === 'production' ? prod : dev
