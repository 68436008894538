import { useEffect, useContext, useState, useMemo } from 'react'

// @mui material components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
// import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import ComplexStatisticsCard from 'components/Cards/StatisticsCards/ComplexStatisticsCard'
import DefaultItem from 'components/Items/DefaultItem'
import DataTable from 'components/Tables/DataTable'

// dashboard components
import EventCalendar from 'components/Calendar'

// zerouno
import { Link, useNavigate } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import utils from 'utils/utils.js'
import { DateTime, Interval } from 'luxon'
import { useStore } from 'context/state.js' // zustand

function MainDashboard() {
  const [showSnackbar, setShowSnackbar] = useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const navigate = useNavigate()

  useEffect(() => {}, [])

  const showError = (error) => {
    setShowSnackbar({
      sh: true,
      msg: error.response
        ? error.response.data.message
        : 'errore di ricezione dati',
      color: 'error'
    })
  }

  const columns = [
    {
      Header: 'numero',
      accessor: 'id'
    },
    {
      Header: 'stato',
      accessor: (d) => {
        return d.stateId === 1 ? 'nuovo' : d.stateId === 2 ? 'aperto' : 'chiuso'
      }
    },
    {
      Header: 'cliente',
      accessor: (d) => {
        return d.registry.businessName.trim()
      }
    },
    {
      Header: 'oggetto',
      accessor: 'subject'
    },
    {
      Header: 'modificato',
      accessor: (d) => {
        return utils.formatDate(d.updatedAt, 'dd/MM/yyyy HH:mm:ss')
      }
    },
    {
      Header: 'assegnato a',
      accessor: (d) => {
        return d.toUser ? d.toUser.surname + ' ' + d.toUser.name : ''
      }
    },
    {
      Header: 'scadenza',
      accessor: (d) => {
        return d.expiryDate
          ? utils.formatDate(d.expiryDate, 'dd/MM/yyyy HH:mm:ss')
          : ''
      }
    },
    {
      Header: 'azioni',
      sortable: false,
      filterable: false,
      width: 100,
      accessor: (row) => (
        <Grid container spacing={1}>
          <Grid item>
            <MDButton
              color='info'
              size='small'
              iconOnly
              component={Link}
              to={`/troubleTickets/${row.id}`}
            >
              <Icon>edit</Icon>
            </MDButton>
          </Grid>
        </Grid>
      )
    }
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={12} sx={{ height: 'max-content' }}>
            <Card>
              <ZUTitleForm
                bgColor='info'
                title='Trouble Tickets'
                icon='event'
              />

              <MDBox p={3} lineHeight={1}>
                <MDButton
                  color='info'
                  component={Link}
                  to='/troubletickets/new'
                  m={1}
                >
                  aggiungi
                </MDButton>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={12} sx={{ height: 'max-content' }}>
            <Card>
              <ZUTitleForm
                bgColor='success'
                title='Calendario Eventi'
                icon='event'
              />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={6} mb={6} sx={{ height: 500 }} />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />

      <Footer />
    </DashboardLayout>
  )
}

export default MainDashboard
